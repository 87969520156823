/**
 * Initializes the specified Promotions Carousel element
 * @param {jqueryElem}} elem jQuery element pointing to a specific `.promotions-slick` element **OR** to an element containing a `.promotions-slick` element
 */
function initPromotionsCarousel(elem) {

    // set the CAROUSEL_ELEM to be the passed in parameter if it is the promotions-slick element or set it to the 
    // nested promotions-slick element
    const CAROUSEL_ELEM = $(elem).hasClass(".promotions-slick") ? $(elem) : $(elem).find(".promotions-slick");

    if( CAROUSEL_ELEM.length > 0 ) {
        
        CAROUSEL_ELEM.slick({
            infinite: true,
            arrows: false,
            dots: false,
            autoplay: true,
            pauseOnHover: false,
            fade: true,
            speed: 1000
        });
    }

    CAROUSEL_ELEM.addClass('is-slick-initialized');


    const PAUSE_PROMO_BUTTON = $('.status .promotions-play-pause-btn');

    PAUSE_PROMO_BUTTON.on('click', function() {
        if( PAUSE_PROMO_BUTTON.hasClass('paused') ) {
            CAROUSEL_ELEM.slick('slickPlay');
            PAUSE_PROMO_BUTTON.removeClass('paused');
        } else {
            CAROUSEL_ELEM.slick('slickPause');
            PAUSE_PROMO_BUTTON.addClass('paused');
        }
    });


}

const withSlickInit = async (...args) => {

    await import(/* webpackChunkName: "slick-carousel" */"slick-carousel");

    initPromotionsCarousel(...args);
};

window.initPromotionsCarousel = withSlickInit;